<template>
  <div class="amount-page">
    <!--充值卡-->
    <div v-if="type === 1 || type === 3 || type === 4">
      <div class="amount-box df fdr alc jc-sb">
        <span class="tip">面值金额</span>
        <span class="money">
          {{ card_amount }}
          {{ business_type === 2 ? "积分" : "元" }}
        </span>
      </div>
      <div class="btn-con" @click.stop="cardTicketRecharge">
        <MyButton title="确认充值" :is_disabled="is_disabled"></MyButton>
      </div>
    </div>
    <!--商品卡-->
    <div v-if="type === 2">
      <div class="goods-list">
        <div
          class="goods-item df fdr alc "
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="select-box flex-center" @click="selectGoods(index, item)">
            <div v-if="index === current_select">
              <icon-svg
                class="main-color select-item"
                icon-class="yi_icon_xuanze"
              />
            </div>
            <div v-else class="selected-item"></div>
          </div>
          <div class="right df fdr alc border-bottom-1px">
            <img class="goods-img" :src="item.base_pic" alt="goods_img" />
            <div class="goods-info df fdc jc-sb">
              <div>
                <div class="name">{{ item.item_name }}</div>
                <span class="describe" v-if="item.item_desc">{{
                  item.item_desc
                }}</span>
              </div>
              <div class="price">￥{{ item.price }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="goods-btn flex-center" @click.stop="toConfirmOrder">
        <MyButton title="确认兑换" :is_disabled="is_disabled"></MyButton>
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "@/components/button/button";
import { getExchangeItem, cardTicketRecharge } from "@/services/my";
import { baseUrl } from "../../../utils/env";

export default {
  name: "ExchangeAmount",
  components: {
    MyButton
  },
  data() {
    return {
      card: "",
      is_disabled: false,
      type: 1, //兑换类型 1充值卡 2商品
      business_type: "", //1现金、2积分
      card_id: "",
      card_ticket_id: "",
      card_amount: "",
      list: [],
      current_select: -1, //当前选择的商品
      card_sn: "",
      card_pass: ""
    };
  },
  methods: {
    getParams() {
      this.type = this.$route.params.type
        ? Number.parseInt(this.$route.params.type)
        : "";
      this.business_type = this.$route.params.business_type
        ? this.$route.params.business_type
        : "";
      this.card_id = this.$route.params.id ? this.$route.params.id : "";
      this.card_ticket_id = this.$route.params.card_ticket_id
        ? this.$route.params.card_ticket_id
        : "";
      this.card_amount = this.$route.params.amount
        ? this.$route.params.amount
        : "";
      this.card_sn = this.$route.params.card_sn
        ? this.$route.params.card_sn
        : "";
      this.card_pass = this.$route.params.card_pass
        ? this.$route.params.card_pass
        : "";
      console.log(this.type, 11111);
      if (this.type) {
        if (this.card_id && this.type === 2) {
          this.getExchangeItem();
        } else if (this.type === 1 && !this.card_amount) {
          this.$router.go(-1);
        }
      } else this.$router.go(-1);
    },
    loadList() {},
    selectGoods(index, item) {
      this.current_select = index;
      console.log(item);
      window.localStorage.removeItem("exchange_data");
      this.$store.commit("setExchangeData", item);
    },
    async cardTicketRecharge() {
      let params = {
        money: this.card_amount,
        card_ticket_id: this.card_ticket_id,
        card_sn: this.card_sn,
        card_pass: this.card_pass
      };
      try {
        const res = await cardTicketRecharge(params);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: "卡券充值成功",
            icon: "success"
          });
          this.$router.go(-1);
        }
      } catch (e) {}
    },
    /**
     * 获取兑换卡的商品列表
     * @returns {Promise<void>}
     */
    async getExchangeItem() {
      try {
        const res = await getExchangeItem(this.card_id);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.list = [...this.list, ...data.list];
        }
      } catch (e) {}
    },
    toConfirmOrder() {
      this.$store.commit("clearCreateOrder");
      this.$router.push({
        name: "submit_order",
        query: {
          add_type: 3 //购物车过来传2，商品详情进来传1 3 兑换商品
        }
      });
    }
  },
  mounted() {
    this.getParams();
  }
};
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.amount-page {
  /*充值卡*/

  .amount-box {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
    background: @color-white;
    padding: 0 0.24rem;
    .sc(0.32rem, @color-dark-grey);
    .wh(100%, 1rem);

    .tip {
      .sc(0.32rem, @color-light-grey);
    }
  }

  /*  商品卡*/

  .goods-list {
    background: @color-white;

    .goods-item {
      .wh(100%, 2rem);

      .select-box {
        .wh(11.2%, 100%);
        padding: 0 0.24rem;

        .select-item {
          .wh(0.36rem, 0.36rem);
        }

        .selected-item {
          .wh(0.36rem, 0.36rem);
          border: 0.01rem solid @color-light-grey;
          border-radius: 50%;
        }
      }

      .right {
        .wh(88.8%, 100%);
        padding: 0.3rem 0;

        .goods-img {
          .wh(1.4rem, 1.4rem);
          margin-right: 0.24rem;
        }

        .goods-info {
          height: 100%;
          .sc(0.32rem, @color-dark-grey);

          .name {
            margin-bottom: 0.1rem;
          }

          .describe {
            .sc(0.22rem, var(--main-color));
            padding: 0 0.12rem;
            min-width: 1.78rem;
            height: 0.36rem;
            line-height: 0.36rem;

            border-radius: 0.18rem;
            border: 0.02rem solid var(--main-color);
          }

          .price {
            .sc(0.32rem, @color-price-red);
            height: 0.4rem;
            line-height: 0.4rem;
            margin-top: 0.18rem;
          }
        }
      }
    }
  }

  .goods-btn {
    .wh(100%, 1.12rem);
    background: @color-white;
    position: fixed;
    bottom: 0;
  }
}
</style>

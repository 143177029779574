var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "amount-page" }, [
    _vm.type === 1 || _vm.type === 3 || _vm.type === 4
      ? _c("div", [
          _c("div", { staticClass: "amount-box df fdr alc jc-sb" }, [
            _c("span", { staticClass: "tip" }, [_vm._v("面值金额")]),
            _c("span", { staticClass: "money" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.card_amount) +
                  "\n        " +
                  _vm._s(_vm.business_type === 2 ? "积分" : "元") +
                  "\n      "
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "btn-con",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.cardTicketRecharge.apply(null, arguments)
                },
              },
            },
            [
              _c("MyButton", {
                attrs: { title: "确认充值", is_disabled: _vm.is_disabled },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.type === 2
      ? _c("div", [
          _c(
            "div",
            { staticClass: "goods-list" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "goods-item df fdr alc" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "select-box flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.selectGoods(index, item)
                        },
                      },
                    },
                    [
                      index === _vm.current_select
                        ? _c(
                            "div",
                            [
                              _c("icon-svg", {
                                staticClass: "main-color select-item",
                                attrs: { "icon-class": "yi_icon_xuanze" },
                              }),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "selected-item" }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "right df fdr alc border-bottom-1px" },
                    [
                      _c("img", {
                        staticClass: "goods-img",
                        attrs: { src: item.base_pic, alt: "goods_img" },
                      }),
                      _c("div", { staticClass: "goods-info df fdc jc-sb" }, [
                        _c("div", [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.item_name)),
                          ]),
                          item.item_desc
                            ? _c("span", { staticClass: "describe" }, [
                                _vm._v(_vm._s(item.item_desc)),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "price" }, [
                          _vm._v("￥" + _vm._s(item.price)),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "goods-btn flex-center",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toConfirmOrder.apply(null, arguments)
                },
              },
            },
            [
              _c("MyButton", {
                attrs: { title: "确认兑换", is_disabled: _vm.is_disabled },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }